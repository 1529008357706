import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';

@Component({
  selector: 'app-nuova-testata-distinta',
  templateUrl: './nuova-testata-distinta.component.html',
  styleUrls: ['./nuova-testata-distinta.component.scss'],
})
export class NuovaTestataDistintaComponent implements OnInit {
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
    public modalCtrl: ModalController,
    private ls: ListiniService,
    private router: Router
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      COD_DISTINTA: ['', Validators.required],
      DES_DISTINTA: ['', Validators.required],
    });
  }

  async doT() {
    let cod_distinta = this.fg.get('COD_DISTINTA').value;
    let des_distinta = this.fg.get('DES_DISTINTA').value;

    await this.ls.doTListinoDistinta(cod_distinta, des_distinta).then(() => {
      this.modalCtrl.dismiss().then(() => {
        this.router.navigate(['/righe-listini-calc-dist'], {
          state: { codT: cod_distinta },
        });
      });
    });
  }
}
