import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PersonalizzazioniArbiService } from 'src/app/servizi/personalizzazioni-arbi.service';

@Component({
  selector: 'app-abinuova-associazione',
  templateUrl: './abinuova-associazione.component.html',
  styleUrls: ['./abinuova-associazione.component.scss'],
})
export class ABINuovaAssociazioneComponent implements OnInit {

  associazioni;

  CAT_AR: string;
  COL_PL: string;

  constructor(public translate: TranslateService,
    private modalCtr: ModalController,
    private personalizzazioniService: PersonalizzazioniArbiService
  ) { }

  async ngOnInit() {
    this.associazioni = await this.personalizzazioniService.getAssociazioni()
  }

  async close(message = 'Modal Closed') {
    await this.modalCtr.dismiss(message);
  }

  async doAssociazione(){
    console.log('dati inseriti : CAT_LT = ' + this.CAT_AR + ' E COL_PL = ' + this.COL_PL);
    await this.personalizzazioniService.doAssociazione(this.CAT_AR, this.COL_PL).then(()=>{
      this.close()
    })
  }

}
