import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { VariantiService } from 'src/app/servizi/varianti.service';
import { variantiStruct } from 'src/app/strutture/varianti.strutture';

@Component({
  selector: 'app-edit-variante',
  templateUrl: './edit-variante.component.html',
  styleUrls: ['./edit-variante.component.scss'],
})
export class EditVarianteComponent implements OnInit {
  fg: FormGroup;
  varianteArrivata: variantiStruct;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private fb: FormBuilder,
    private vs: VariantiService
  ) {}

  ngOnInit() {
    console.log('edit variante', this.varianteArrivata);
    this.fg = this.fb.group({
      CODVAR: [this.varianteArrivata.CODVAR, Validators.required],
      DESVAR: [this.varianteArrivata.DESVAR, Validators.required],
      ALIAS: [this.varianteArrivata.ALIAS],
      TRADGEST: [this.varianteArrivata.TRADGEST],
      TIPO_VARIANTE: [this.varianteArrivata.TIPO_VARIANTE],
      NOTA: [this.varianteArrivata.NOTA],
      ORDINAMENTO: [this.varianteArrivata.ORDINAMENTO],
      OPZ_FIXED: [this.varianteArrivata.OPZ_FIXED],
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async editVariante() {
    const modifiedFields = { campo_var: '', valore: '' };
    Object.keys(this.fg.controls).forEach((controlName) => {
      const control = this.fg.get(controlName);
      if (
        control.dirty &&
        control.value !== this.varianteArrivata[controlName]
      ) {
        modifiedFields.campo_var = controlName;
        modifiedFields.valore = control.value;
      }
    });
    console.log('Campi modificati:', modifiedFields);

    if (modifiedFields.campo_var != '') {
      await this.vs
        .editVariante(
          this.varianteArrivata.CODVAR,
          modifiedFields.campo_var,
          modifiedFields.valore
        )
        .then(() => {
          //this.modalCtrl.dismiss();
        });
    }
  }
}
