/* eslint-disable @typescript-eslint/naming-convention */
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export class TranslationLoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {


    /**
     * qui dovremmo inserire tutti i valori di default di ogni parametro,
     * per poi reimpostarli dopo la chiamata al server per le lingue.
     * serve solo come fallback in caso di errori di comunicazioni
     */
    if (lang === 'en') {
      return of({
        HELLO: 'Hi there {{ value }}'
      });
    } else {
      return of({
        HELLO: 'Hi there {{ value }}'
      });
    }
  }
}
