import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VaultService {
  constructor(private common: CommonFunctionService) {}

  async checkVault() {
    const result = await this.common.checkVault();
    console.log('check vault', result);
  }

  async getContentVault(directory: string) {
    const content = await this.common.getContentVault(directory);
    //console.log('content vault', content);
    return content;
  }

  async uploadVault(file: File, path: string) {
    const response = await this.common.uploadVault(file, path);
    console.log('upload vault', response);
  }

  async createFolderVault(path: string) {
    const response = await this.common.createFolderVault(path);
    console.log('create folder vault', response);
  }

  async deleteFileVault(pathAndFileName: string) {
    const response = await this.common.deleteFileVault(pathAndFileName);
    console.log('delete file', response);
  }

  async deleteFolderVault(path: string) {
    const response = await this.common.deleteFolderVault(path);
    console.log('delete folder', response);
  }

  async downloadVault(path: string) {
    const response = await this.common.downloadVault(path);
    console.log('download file', response);
  }

  async copyFileVault(from_path: string, to_path: string) {
    const response = await this.common.copyFileVault(from_path, to_path);
    console.log('copia file', response);
  }

  async getConfigVault() {
    const response = await this.common.getConfigVault();
    console.log('get configuration', response);
    return response;
  }
}
