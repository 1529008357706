import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AlertController,
  ModalController,
  ToastController,
  ViewWillEnter,
} from '@ionic/angular';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { ProjectMillaService } from 'src/app/servizi/projectMilla.service';
import { millaUsers } from 'src/app/strutture/millaUsers.strutture';
import { scontiUtenteMilla } from 'src/app/strutture/scontiUtenteMilla.strutture';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements ViewWillEnter, OnInit {
  utente: millaUsers;

  fg: FormGroup;

  mail: string;
  nome: string;
  cognome: string;
  listino: string;
  lingua: string;
  data_validita_listino: number;
  data_attivazione: number;
  data_scadenza: number;
  codice: string;
  link_invito: string;

  site: string = '';

  catalogo: any;

  sconti: scontiUtenteMilla[] = [];
  showSaveButton: string = '';
  sconto_field: string = '';
  qta_sconto: number = 0;

  constructor(
    public modalCtrl: ModalController,
    private millaService: ProjectMillaService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private globalvar: GlobalVariableService
  ) {}

  ngOnInit() {
    this.mail = this.utente.mail;
    this.nome = this.utente.name;
    this.cognome = this.utente.surname;

    this.globalvar.getCatalogoAttivo().subscribe((cat) => {
      this.catalogo = cat.UTENTE;
    });
  }

  async ionViewWillEnter(): Promise<void> {
    console.log('utente', this.utente);
    this.site =
      (await this.millaService.getUrlSite()) +
      '?plistActivationCode=' +
      this.utente.code +
      '&time=' +
      this.utente.time_out +
      '&listino=' +
      this.utente.listino +
      '&time_agree=' +
      this.utente.time_agree +
      '&data_validita=' +
      this.utente.data_validita_listino;
    this.getScontiUtenti();
  }

  async doEditUser() {
    console.log(
      'modifico i dati',
      this.nome,
      this.cognome,
      this.mail,
      this.utente.code
    );
    await this.millaService.doEditUser(
      this.nome,
      this.cognome,
      this.mail,
      this.utente.code
    );
    this.modalCtrl.dismiss();
  }

  async getScontiUtenti() {
    this.sconti = await this.millaService.getScontiUtente(
      this.utente.mail,
      this.utente.code
    );
  }

  async prepareSetScontoUtente(e: any) {

    console.log('event', e)

    this.sconto_field = e.target.id;
    this.qta_sconto = e.target.value;
    console.log('sconto_field', this.sconto_field)
    console.log('value', e.target.value)

    this.showSaveButton = this.sconto_field;
  }

  async setScontoUtente() {

    console.log(
      'Salvo lo sconto con i dati:',
      'mai: l' + this.utente.mail + ' ||| ',
      'code: ' + this.utente.code + ' ||| ',
      'sconto_field: ' + this.sconto_field + ' ||| ',
      'qta_sconto: ' + this.qta_sconto
    );

    await this.millaService.setScontoUtente(this.utente.mail, this.utente.code, this.sconto_field, this.qta_sconto);
    this.showSaveButton = '';

  }

  async resend() {
    await this.millaService
      .millaActivation(
        this.mail,
        this.nome,
        this.cognome,
        this.utente.listino,
        this.utente.lingua,
        this.catalogo.REST_KEY,
        this.utente.data_validita_listino,
        'RESEND'
      )
      .then(async () => {
        const alert = await this.alertCtrl.create({
          mode: 'ios',
          message: 'Email inviata!',
          buttons: ['Ok'],
        });
        await alert.present();
        await alert.onDidDismiss().then(() => {
          this.modalCtrl.dismiss();
        });
      });
  }

  copy() {
    navigator.clipboard.writeText(this.site);
    this.presentToast();
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Link copiato!',
      duration: 1500,
      position: 'top',
      mode: 'ios',
    });

    await toast.present();
  }
}

export class campi {
  id: string;
  readonly: boolean;
  value: any;

  constructor(id: string, readonly: boolean, value: any) {
    this.id = id;
    this.readonly = readonly;
    this.value = value;
  }
}
