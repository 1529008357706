/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
// import { printLog } from '../lib';
import { invitoDaInviareStruct } from '../strutture/invitoDaInviare.strutture';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';
import { TranslateService } from '@ngx-translate/core';
import { utenteErrataStruct } from '../strutture/utenteErrataStruct.strutture';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  header: string = '';
  message: string = '';

  catalogoAttivo = null;
  private destroy = new Subject();

  constructor(
    private globalVar: GlobalVariableService,
    private commons: CommonFunctionService,
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {}

  async getUtenti(){
    const utenti = await this.commons.ritornaUtentiCatalogo();
    console.log('u cat', utenti)
    return utenti;
  }

  async deleteUtente(uuid: string){
    const responseUtenteRimosso = await this.commons.deleteUtente(uuid);
  }

  async aggiungiUtente(payload_invito: invitoDaInviareStruct){
    if(this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA){
      const responseAggiungiUtente = await this.commons.doCreaInvito(payload_invito);
      const risp = JSON.stringify(responseAggiungiUtente).split(':').pop().replace('}', '').slice(1, -1);
      if(risp === '200'){
        this.header = 'Avviso';
        this.message = 'Utente invitato';
      } else {
        this.header = 'Errore';
        this.message = this.translate.instant(risp);
      }
      const alert = await this.alertCtrl.create({
mode: 'ios',
        header: this.header,
        message: this.message,
        buttons: ['Ok']
      });
      await alert.present();

    }
  }

  async eliminaInvito(invite_id: string){
    const responseEliminaInvito = await this.commons.eliminaInvito(invite_id);
  }

  async ricercaUtenti(ricercaString: string){
    const response = await this.commons.ricercaUtentiCatalogo(ricercaString);
  }

  async doNewUser(userdata: utenteErrataStruct){
    const response = await this.commons.doNewUser(userdata);
    console.log('do nuovo utente', response);
  }




}
