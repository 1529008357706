import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PersonalizzazioniSantaluciaService } from 'src/app/servizi/personalizzazioni-santalucia.service';
import { SantaluciaNuovaAssociazioneComponent } from '../santalucia-nuova-associazione/santalucia-nuova-associazione.component';

@Component({
  selector: 'app-associa-colonne-santalucia',
  templateUrl: './associa-colonne-santalucia.component.html',
  styleUrls: ['./associa-colonne-santalucia.component.scss'],
})
export class AssociaColonneSantaluciaComponent implements OnInit {
  associazioni;

  modalDataResponse: any;

  constructor(
    public translate: TranslateService,
    private modalCtrl: ModalController,
    private personalizzazioniService: PersonalizzazioniSantaluciaService
  ) {}

  async ngOnInit() {
    this.associazioni = await this.personalizzazioniService.getAssociazioni();
    // printLog('associazioni arrivate', associazioni);
  }

  async delAssociazione(catLT: string) {
    const del = await this.personalizzazioniService.delAssociazione(catLT);
  }

  async openAssociazione() {
    const modal = await this.modalCtrl.create({
      component: SantaluciaNuovaAssociazioneComponent,
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
      }
    });

    return await modal.present();
  }

  async close() {
    const closeModal = 'Modal Closed';
    await this.modalCtrl.dismiss(closeModal);
  }
}
