import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { righeListiniStruct } from 'src/app/strutture/listini calcolati distinta/righeListini.strutture';

@Component({
  selector: 'app-edit-riga-distinta',
  templateUrl: './edit-riga-distinta.component.html',
  styleUrls: ['./edit-riga-distinta.component.scss'],
})
export class EditRigaDistintaComponent implements OnInit {
  fg: FormGroup;
  @Input() codT: string;
  @Input() riga: righeListiniStruct;

  constructor(
    private fb: FormBuilder,
    public modalCtrl: ModalController,
    private ls: ListiniService
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      COL_START: [this.riga.COL_START, Validators.required],
      SEGNO: [this.riga.SEGNO, Validators.required],
      PERC: [this.riga.PERC, Validators.required],
      ARROT: [this.riga.ARROT, Validators.required],
    });
  }

  async editR() {
    const modifiedFields = { field: '', value: '' };
    Object.keys(this.fg.controls).forEach((controlName) => {
      const control = this.fg.get(controlName);
      if (control.dirty && control.value !== this.riga[controlName]) {
        modifiedFields.field = controlName;
        modifiedFields.value = control.value;
      }
    });
    console.log(
      'Campi modificati:',
      this.codT,
      modifiedFields.field,
      modifiedFields.value,
      this.riga.COL_DEST
    );

    await this.ls
      .editRListinoDistinta(
        this.codT,
        modifiedFields.field,
        modifiedFields.value,
        this.riga.COL_DEST
      )
      .then(() => {
        this.modalCtrl.dismiss();
      });
  }
}
