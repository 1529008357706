import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';

@Component({
  selector: 'app-nuova-riga-distinta',
  templateUrl: './nuova-riga-distinta.component.html',
  styleUrls: ['./nuova-riga-distinta.component.scss'],
})
export class NuovaRigaDistintaComponent implements OnInit {
  fg: FormGroup;
  @Input() codT: string = '';

  constructor(
    private fb: FormBuilder,
    public modalCtrl: ModalController,
    private ls: ListiniService
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      COL_START: ['', Validators.required],
      SEGNO: ['', Validators.required],
      PERC: ['', Validators.required],
      COL_DEST: ['', Validators.required],
      ARROT: ['', Validators.required],
    });
  }

  async doR() {
    let col_start = this.fg.get('COL_START').value;
    let segno = this.fg.get('SEGNO').value;
    let perc = this.fg.get('PERC').value;
    let col_dest = this.fg.get('COL_DEST').value;
    let arrot = this.fg.get('ARROT').value;

    console.log(
      'passo i dati per la nuova riga',
      this.codT,
      col_start,
      segno,
      perc,
      col_dest,
      arrot
    );

    await this.ls
      .doRListinoDistinta(this.codT, col_start, segno, perc, col_dest, arrot)
      .then(() => {
        this.modalCtrl.dismiss();
      });
  }
}
