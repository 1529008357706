import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { testateListiniStruct } from 'src/app/strutture/listini calcolati distinta/testateListini.strutture';
import { listiniStruct } from 'src/app/strutture/listini.strutture';
import { ViewerDistinteComponent } from '../viewer-distinte/viewer-distinte.component';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { APPID } from 'src/app/default-values';

@Component({
  selector: 'app-nuovo-listino-calcolato-distinta',
  templateUrl: './nuovo-listino-calcolato-distinta.component.html',
  styleUrls: ['./nuovo-listino-calcolato-distinta.component.scss'],
})
export class NuovoListinoCalcolatoDistintaComponent implements OnInit {
  fg: FormGroup;
  listini: listiniStruct[] = [];
  distinte: testateListiniStruct[] = [];

  disintaDaPassare: string = '';

  showProgress: boolean = false;
  progressValue: number;
  es;

  permitClosingDialog: boolean = true;

  constructor(
    private fb: FormBuilder,
    public modalCtrl: ModalController,
    private ls: ListiniService,
    private globalvar: GlobalVariableService
  ) {}

  async ngOnInit() {
    this.fg = this.fb.group({
      LISTINO_START: ['', Validators.required],
      LISTINO_END: ['', Validators.required],
    });
    await this.getListini();
    await this.getT();
  }

  async getListini() {
    this.listini = await this.ls.getListini();
  }

  async getT() {
    this.distinte = await this.ls.getTListinoDistinta();
  }

  async openViewerDistinte() {
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: ViewerDistinteComponent,
      cssClass: 'big',
    });
    await modal.present();
    modal.onDidDismiss().then((data) => {
      console.log('devo inserire la distinta', data['data']);
      this.disintaDaPassare = data['data'];
    });
  }

  async doListinoCalcolatoDistinta() {
    let listino_start = this.fg.get('LISTINO_START').value;
    let listino_end = this.fg.get('LISTINO_END').value;

    console.log(
      'distinta da passare',
      this.disintaDaPassare,
      listino_start,
      listino_end
    );

    this.showProgress = true;
    this.permitClosingDialog = false;
    const chiamataApi =
      'https://api.price-list.it/inprintapi/api1/doListinoCalcolatoDistinta.php?APPID=' +
      APPID +
      '&ID_CATALOGO=' +
      this.globalvar.getIdCatalogoAttivo() +
      '&COD_DISTINTA=' +
      this.disintaDaPassare +
      '&LISTINO_START=' +
      listino_start +
      '&LISTINO_END=' +
      listino_end +
      '&DEBUG_PROGRESS=0';
    console.log('chiamata api', chiamataApi);
    this.es = new EventSource(chiamataApi);
    this.es.addEventListener('message', (e) => {
      const result = JSON.parse(e.data);
      console.log(result);
    });
    this.es.addEventListener('message', (e) => {
      const result = JSON.parse(e.data);
      this.progressValue = result.progress / 100;
      console.log(this.progressValue / 100);
      // printLogresult.message);
      if (e.lastEventId === 'CLOSE') {
        // printLog('Received CLOSE closing');
        this.es.close();
        this.modalCtrl.dismiss();
        const pBar = document.getElementById('progressor') as HTMLInputElement;
        pBar.value = pBar.max;
        this.modalCtrl.dismiss();
      } else {
        const pBar = document.getElementById('progressor') as HTMLInputElement;
        pBar.value = result.progress;
        const perc = document.getElementById('percentage');
        perc.innerHTML = result.progress.toFixed(0) + '%';
        perc.style.width =
          Math.floor(pBar.clientWidth * (result.progress / 100)) + 15 + 'px';
      }
    });

    this.es.addEventListener('error', (e) => {
      // printLog('Error occurred', e);
      this.es.close();
    });
  }
}
