<ion-header>
  <ion-toolbar>
    <ion-title>Nuova composizione</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formDoNuovaT">
    <ion-item>
      <ion-input label="Numero ordine" labelPlacement="stacked" type="text" formControlName="cod_compo"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Descrizione" labelPlacement="stacked" type="text" formControlName="des"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Larghezza" labelPlacement="stacked" type="number" formControlName="diml"
        placeholder="Inserisci la misura in millimetri, senza virgole"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Altezza" labelPlacement="stacked" type="number" formControlName="dima"
        placeholder="Inserisci la misura in millimetri, senza virgole"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Profondità" labelPlacement="stacked" type="number" formControlName="dimp"
        placeholder="Inserisci la misura in millimetri, senza virgole"></ion-input>
    </ion-item>
    <ion-item>
      <ion-textarea label="Data 1" formControlName="d1"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-textarea label="Data 2" formControlName="d2"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-textarea label="Data 3" formControlName="d3"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-textarea label="Data 4" formControlName="d4"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-textarea label="Data 5" formControlName="d5"></ion-textarea>
    </ion-item>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' [disabled]="!formDoNuovaT.valid" (click)="doNuovaT()"
      color="primary">{{this.translate.instant('123')}}</ion-button>
  </ion-toolbar>
</ion-footer>
