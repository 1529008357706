<ion-header class="ion-text-center ion-no-border">
  <ion-toolbar>
    <ion-title>
      Esporta
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="none">
    <ion-select label="Seleziona il listino" labelPlacement="stacked" mode="ios"
      (ionChange)="getListinoSelezionato($event)">
      <ion-select-option *ngFor="let listino of listini">{{listino.COD_LISTINO}}</ion-select-option>
    </ion-select>
  </ion-item>

</ion-content>

<ion-footer class="ion-text-center ion-no-border">
  <ion-toolbar>
    <ion-button mode="ios" [disabled]="this.listinoSelezionato !== ''" (click)="doExport()">Esporta</ion-button>
  </ion-toolbar>
</ion-footer>