import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ListiniService } from 'src/app/servizi/listini.service';
import { PersonalizzazioniArbiService } from 'src/app/servizi/personalizzazioni-arbi.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

@Component({
  selector: 'app-export-rivenditori',
  templateUrl: './export-rivenditori.component.html',
  styleUrls: ['./export-rivenditori.component.scss'],
})
export class ExportRivenditoriComponent implements OnInit {
  listini: listiniStruct[] = [];
  listinoSelezionato: string = '';

  constructor(
    private ls: ListiniService,
    private ps: PersonalizzazioniArbiService
  ) {}

  ngOnInit() {
    this.getListini();
  }

  getListinoSelezionato(e: any) {
    this.listinoSelezionato = e.detail.value;
    console.log('listino selezionato', this.listinoSelezionato);
  }

  async doExport() {
    if (this.listinoSelezionato !== '') {
      console.log("posso fare l'esportazione");
      //await this.ps.doExportRivenditori(this.listinoSelezionato);
    }
  }

  async getListini() {
    this.listini = await this.ls.getListini();
  }
}
