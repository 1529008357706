<ion-header class="ion-no-border" mode='ios' *ngIf="showProgress === false">
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" (click)="close()"></ion-icon>
    <ion-title>{{this.translate.instant('182')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-header class="ion-no-border" mode='ios' *ngIf="showProgress === true">
  <ion-toolbar>
    <ion-title>{{this.translate.instant('196')}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="showProgress === false">
    <ion-row>
      <ion-col size="12">
        <span>{{ errorLabel }}</span>
      </ion-col>
    </ion-row>
  </div>


<div *ngIf="showProgress === false">
  <ion-grid *ngFor="let errore of erroriArrivati">
    <ion-row *ngIf="this.w_error === true">
      <ion-col>
        <ion-textarea class="warning" disabled>
          {{errore.FLAG_IMPORT + ' ' + errore.MESSAGGIO}}
        </ion-textarea>
      </ion-col>
    </ion-row>
    <ion-row  *ngIf="this.c_error === true">
      <ion-col>
        <ion-textarea class="critical" disabled>
          {{errore.FLAG_IMPORT + ' ' + errore.MESSAGGIO}}
        </ion-textarea>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>


  <ion-grid *ngIf="showProgress === true">
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-progress-bar [value]="progressValue" color="primary" max="100" id="progressor"></ion-progress-bar> <!-- i valori vanno da 0 a 1, l'api rimanda da 0 a 100, gestiscila -->
        <br>
        <span id="percentage">0</span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-footer class="ion-no-border">
    <ion-row *ngIf="prosegui === true && showProgress === false">
      <ion-col size="12" text-center>
        <ion-button mode='ios' color="primary"  (click)="doImporta()">{{this.translate.instant('160')}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</ion-content>
