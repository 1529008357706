import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PersonalizzazioniArbiService } from 'src/app/servizi/personalizzazioni-arbi.service';
import { ABINuovaAssociazioneComponent } from '../abinuova-associazione/abinuova-associazione.component';

@Component({
  selector: 'app-arbi-associa-colonne',
  templateUrl: './arbi-associa-colonne.component.html',
  styleUrls: ['./arbi-associa-colonne.component.scss'],
})
export class ArbiAssociaColonneComponent implements OnInit {

  associazioni;

  modalDataResponse: any;

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private personalizzazioniService: PersonalizzazioniArbiService
  ) { }

  async ngOnInit() {

    this.associazioni = await this.personalizzazioniService.getAssociazioni();
    // printLog('associazioni arrivate', associazioni);
  }

  async delAssociazione(catLT: string){
    await this.personalizzazioniService.delAssociazione(catLT).then(async ()=>{
      this.associazioni = await this.personalizzazioniService.getAssociazioni();
    })

  }

  async openAssociazione(){
    const modal = await this.modalCtrl.create({
      component: ABINuovaAssociazioneComponent
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
      }
    });

    await modal.present();
    modal.onDidDismiss().then(async ()=>{
      this.associazioni = await this.personalizzazioniService.getAssociazioni();
    })
  }

  async close() {
    const closeModal = 'Modal Closed';
    await this.modalCtrl.dismiss(closeModal);
  }

}
