import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { VariantiService } from 'src/app/servizi/varianti.service';
import { opzioniStruct } from 'src/app/strutture/opzioni.strutture';
import { variantiStruct } from 'src/app/strutture/varianti.strutture';
import { OpzioneDerivataComponent } from '../opzione-derivata/opzione-derivata.component';

@Component({
  selector: 'app-variante-derivata',
  templateUrl: './variante-derivata.component.html',
  styleUrls: ['./variante-derivata.component.scss'],
})
export class VarianteDerivataComponent implements OnInit {
  @Input() opzione: opzioniStruct;
  @Input() colonna: string;
  @Input() alias: string;
  selectedSegment: string = 'assoc';
  varianti: variantiStruct[] = [];

  constructor(
    public modalCtrl: ModalController,
    private router: Router,
    private vs: VariantiService
  ) {}

  ngOnInit() {}

  async openOpzioni(v: string) {
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: OpzioneDerivataComponent,
      cssClass: 'big',
      componentProps: {
        opzione: this.opzione,
        colonna: this.colonna,
        codvar: v,
        alias: this.alias,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      this.selectedSegment = 'assoc';
    });
  }

  async changeVariantiToView(event: CustomEvent) {
    this.selectedSegment = event.detail.value;

    if (this.selectedSegment === 'nonassoc') {
      let variantiTot = await this.vs.getVarianti();
      this.varianti = variantiTot.filter(
        (var1) => !this.varianti.some((var2) => var2.CODVAR === var1.CODVAR)
      );
    } else {
      //this.varianti = await this.as.getAssociaCol(this.colonna);
    }
  }
}
