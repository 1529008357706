import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OpzioniService } from 'src/app/servizi/opzioni.service';
import { VariantiService } from 'src/app/servizi/varianti.service';
import { opzioniStruct } from 'src/app/strutture/opzioni.strutture';
import * as EXIF from 'exif-js';
import { VaultService } from 'src/app/servizi/vault.service';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';

@Component({
  selector: 'app-edit-opzione',
  templateUrl: './edit-opzione.component.html',
  styleUrls: ['./edit-opzione.component.scss'],
})
export class EditOpzioneComponent implements OnInit {
  fg: FormGroup;
  opzioneArrivata: opzioniStruct;
  textureName: string = '';
  fileToUpload: any;

  srcImage: string = '';

  constructor(
    public translate: TranslateService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private os: OpzioniService,
    private alertCtrl: AlertController,
    private vaultS: VaultService,
    private globalvar: GlobalVariableService
  ) {}

  ngOnInit() {
    console.log('edit opzione', this.opzioneArrivata);

    this.fg = this.fb.group({
      CODOPZ: [this.opzioneArrivata.CODOPZ, Validators.required],
      DESOPZ: [this.opzioneArrivata.DESOPZ, Validators.required],
      TRADGEST: [this.opzioneArrivata.TRADGEST],
      DATA_MATERIAL: [this.opzioneArrivata.DATA_MATERIAL],
    });

    this.getTextureFromVault();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async editOpzione() {
    const modifiedFields = { campo_opz: '', valore: '' };
    Object.keys(this.fg.controls).forEach((controlName) => {
      const control = this.fg.get(controlName);
      if (
        control.dirty &&
        control.value !== this.opzioneArrivata[controlName]
      ) {
        modifiedFields.campo_opz = controlName;
        modifiedFields.valore = control.value;
      }
    });
    console.log('Campi modificati:', modifiedFields);

    if (modifiedFields.campo_opz != '') {
      await this.os
        .editOpzione(
          this.opzioneArrivata.CODOPZ,
          this.opzioneArrivata.CODVAR,
          modifiedFields.campo_opz,
          modifiedFields.valore
        )
        .then(() => {
          this.modalCtrl.dismiss();
        });
    } else {
      this.modalCtrl.dismiss();
    }
  }

  //* upload texture
  async presentAlert(message: string) {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: 'Attenzione',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  onTextureSelected(event: any): void {
    const element = event.target as HTMLInputElement;
    const file = element?.files?.[0];

    this.textureName = file.name;
    this.fileToUpload = file;

    if (file) {
      const reader = new FileReader();
      console.log(file);

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const imageUrl = e.target?.result as string;
        const img = new Image();
        img.onload = () => {
          if (img.width === 200 && img.height === 200) {
            // L'immagine è della dimensione corretta
            console.log('Immagine valida');
            this.checkDSDirExists();
          } else {
            this.presentAlert(
              "La dimensione dell'immagine deve essere 200px per 200px"
            );
            console.log(
              'Immagine non valida',
              'width:' + img.width,
              'height:' + img.height
            );
          }
        };
        img.src = imageUrl;
      };

      reader.readAsDataURL(file);
    }
  }

  async checkDSDirExists() {
    //. Controllo se ho la cartella "deepspace"
    let hasDeepspaceDir = await this.vaultS.getContentVault('deepspace');
    if (hasDeepspaceDir.length > 0) {
      this.checkOpzDirExists();
    } else {
      //. Creo la cartella
      await this.vaultS.createFolderVault('deepspace/').then(() => {
        this.checkOpzDirExists();
      });
    }
  }

  async checkOpzDirExists() {
    //. Controllo se ho la cartella "opzioni" nella cartella "deepspace"
    let hasOptionDir = await this.vaultS.getContentVault('deepspace/opzioni');
    console.log('hasOptionDir', hasOptionDir);
    if (hasOptionDir.length > 0) {
      this.doUpload();
    } else {
      //. Creo la cartella
      await this.vaultS.createFolderVault('deepspace/opzioni/').then(() => {
        this.checkOpzDirExists();
      });
    }
  }

  async doUpload() {
    console.log("sono pronto per l'upload");

    console.log('carico il file', this.fileToUpload);
    let estensione = this.fileToUpload.name.split('.').pop();
    let nome = this.opzioneArrivata.CODVAR + '_' + this.opzioneArrivata.CODOPZ;

    const formData = new FormData();
    formData.append('file', this.fileToUpload, nome + '.' + estensione);

    let filedapassare = formData.get('file') as File;
    console.log('file finale da passare', filedapassare);

    await this.vaultS.uploadVault(filedapassare, 'deepspace/opzioni/');
  }

  //* show texture
  async getTextureFromVault() {
    let nomeFile =
      this.opzioneArrivata.CODVAR + '_' + this.opzioneArrivata.CODOPZ;
    let file = await this.vaultS.getContentVault(
      'deepspace/opzioni/' + nomeFile
    );

    let image =
      'https://api.price-list.it/inprintapi/api1/vault/viewerVault.php?APPID=2389045723&INI=' +
      this.globalvar.getDBNomeCatalogo() +
      '&PATH=' +
      file[0][0];

    console.log('immagine arrivata', image);
    this.srcImage = image;

    if (this.srcImage != '') {
      this.textureName = file[0][0];
    }
  }

  async openImage() {
    window.open(this.srcImage);
  }
}
