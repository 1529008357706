import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ModalController,
  AlertController,
  ViewWillEnter,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AssociaColVarOpzService } from 'src/app/servizi/associaColVarOpz.service';
import { VariantiService } from 'src/app/servizi/varianti.service';
import { opzioniStruct } from 'src/app/strutture/opzioni.strutture';
import { VarianteDerivataComponent } from '../variante-derivata/variante-derivata.component';

@Component({
  selector: 'app-opzione-derivata',
  templateUrl: './opzione-derivata.component.html',
  styleUrls: ['./opzione-derivata.component.scss'],
})
export class OpzioneDerivataComponent implements ViewWillEnter {
  @Input() codvar: string;
  @Input() colonna: string;
  @Input() alias: string;
  opzioni: any[] = [];
  opzioniAssociate: any;

  constructor(
    public translate: TranslateService,
    private vs: VariantiService,
    private route: ActivatedRoute,
    private as: AssociaColVarOpzService,
    private router: Router,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  back() {
    this.router.navigate(['variante-derivata', { colonna: this.colonna }]);
  }

  async ionViewWillEnter() {
    console.log('alias', this.alias);
    if (this.alias == '') {
      this.opzioni = await this.vs.getVariante(this.codvar);
    } else {
      this.opzioni = await this.vs.getVariante(this.alias);
    }

    console.log('opzioni der', this.opzioni);

    let associazioni = await this.as.getAssociaColVar(
      this.codvar,
      this.colonna
    );
    if (this.opzioni[1].length > 0 && associazioni.length > 0) {
      this.opzioniAssociate = this.opzioni[1].filter((opzione) =>
        associazioni.some(
          (associazione) => associazione.CODOPZ === opzione.CODOPZ
        )
      );
      console.log('OPZIONI associate', this.opzioniAssociate);
    }
  }

  isChecked(codopz: string): boolean {
    if (this.opzioniAssociate != undefined) {
      let check = this.opzioniAssociate.some(
        (opzione) => opzione.CODOPZ === codopz
      );
      return check;
    } else {
      return false;
    }
  }

  async flagOpzione(event: CustomEvent, codopz: string) {
    console.log(`Checkbox ${codopz} changed to ${event.detail.checked}`);
    if (event.detail.checked === true) {
      await this.as.doAssociaVarColOpz(
        codopz,
        this.codvar,
        this.colonna,
        '',
        ''
      );
    } else if (event.detail.checked === false) {
      await this.as.delAssociaVarColOpz(codopz, this.codvar, this.colonna);
    }
  }

  async openAlertOptions(opzione: opzioniStruct) {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: "Scegli un'azione",
      inputs: [
        {
          label: 'Deriva',
          type: 'radio',
          value: 'deriva',
          handler: () => {
            this.setOpzScope('deriva', opzione).then(() => {
              this.alertCtrl.dismiss();
            });
          },
        },
        {
          label: 'Variante',
          type: 'radio',
          value: 'variante',
          handler: (data) => {
            alert.dismiss(data.value);
          },
        },
        {
          label: 'Codice',
          type: 'radio',
          value: 'codice',
          handler: (data) => {
            alert.dismiss(data.value);
          },
        },
      ],
    });
    await alert.present();
    alert.onDidDismiss();
  }

  //* derive
  async setOpzScope(opzscope: string, opzione: opzioniStruct) {
    switch (opzscope) {
      case 'deriva':
        this.openDerive(opzione);
        break;

      case 'logica':
        break;

      case 'codice':
        break;
    }
  }

  async openDerive(opzione: opzioniStruct) {
    console.log("apro derive con l'opzione", opzione);
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: VarianteDerivataComponent,
      componentProps: {
        opzione: opzione,
        colonna: this.colonna,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      this.ionViewWillEnter();
    });
  }
}
