<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="1">
        <ion-icon style="float: left;" name="chevron-back-outline" (click)="back()"></ion-icon>
      </ion-col>
      <ion-col>
        <ion-text style="font-size: 1.3rem; font-weight: 600;">Opzioni della variante </ion-text>
      </ion-col>
    </ion-row>

    <ion-row class="header-row">
      <ion-col size="2">
        <ion-text class="header-text">Codice</ion-text>
      </ion-col>
      <ion-col size="8">
        <ion-text class="header-text">Descrizione</ion-text>
      </ion-col>
      <ion-col size="1">
        <ion-text class="header-text" style="float: right;">Associa</ion-text>
      </ion-col>
      <ion-col size="1">
        <ion-text class="header-text" style="float: right;"></ion-text>
      </ion-col>
    </ion-row>


    <ion-row class="colored" *ngFor="let o of opzioni[1]; let i = index;">
      <ion-col size="2">
        <span><b>{{o.CODOPZ}}</b></span>
      </ion-col>
      <ion-col size="8">
        <span>{{o.DESOPZ}}</span>
      </ion-col>
      <ion-col size=" 1">
        <ion-checkbox style="float: right;" mode="ios" [checked]="isChecked(o.CODOPZ)"
          (ionChange)="flagOpzione($event, o.CODOPZ)"></ion-checkbox>
      </ion-col>
      <ion-col size="1">
        <ion-icon name="ellipsis-vertical-outline" (click)="openAlertOptions(o)"></ion-icon>
      </ion-col>
      <!-- <ion-col size="1" *ngFor="let c of columns;">
        <input type="text" id="col{{c}}{{i}}" [value]="o[c]" (focusout)="modificaC(o.CODOPZ, c, i)">
      </ion-col> -->
    </ion-row>
  </ion-grid>

</ion-content>