import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalizzazioniArbiService {
  constructor(private commons: CommonFunctionService) {}

  async getAssociazioni() {
    const associazioniArrivateDaApi = await this.commons.getAssociazioniARBI();
    return associazioniArrivateDaApi;
  }

  async doAssociazione(catARBI: string, colPL: string) {
    const result = await this.commons.doAssociazioneARBI(catARBI, colPL);
  }

  async delAssociazione(catARBI: string) {
    const result = await this.commons.delAssociazioneARBI(catARBI);
  }

  async doExportRivenditori(cod_listino: string) {
    const doExport = await this.commons.doExportRivenditori(cod_listino);
    console.log('result do export', doExport);
  }
}
