<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Modifica variante {{varianteArrivata.CODVAR}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="fg">

    <ion-item>
      <ion-input label="Codice" labelPlacement="stacked" formControlName="CODVAR" readonly></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Descrizione" labelPlacement="stacked" formControlName="DESVAR"
        (ionBlur)="editVariante()"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Alias" labelPlacement="stacked" formControlName="ALIAS" (ionBlur)="editVariante()"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Traduzione gestionale" labelPlacement="stacked" formControlName="TRADGEST"
        (ionBlur)="editVariante()"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Tipo variante" labelPlacement="stacked" formControlName="TIPO_VARIANTE"
        (ionBlur)="editVariante()"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Nota" labelPlacement="stacked" formControlName="NOTA" (ionBlur)="editVariante()"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Ordinamento" labelPlacement="stacked" formControlName="ORDINAMENTO"
        (ionBlur)="editVariante()"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Opzioni fissate" labelPlacement="stacked" formControlName="OPZ_FIXED"
        (ionBlur)="editVariante()"></ion-input>
    </ion-item>


  </form>
</ion-content>

<ion-footer class="ion-no-border" mode='ios'>
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' (click)="modalCtrl.dismiss()" color="primary" [disabled]="!fg.valid">Prosegui</ion-button>
  </ion-toolbar>
</ion-footer>
