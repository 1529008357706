/* eslint-disable @typescript-eslint/naming-convention */
export class articoliStruct {

  public COD: string;
  public DES: string;
  public DIML: number;
  public DIMA: number;
  public DIMP: number;
  public PESOL: number;
  public PESON: number;
  public VOLUME: number;
  public COLLI: string;
  public UM: string;

  private isEditableCOD = false;
  private isEditableDES = false;
  private isEditableDIML = false;
  private isEditableDIMA = false;
  private isEditableDIMP = false;
  private isEditablePESOL = false;
  private isEditablePESON = false;
  private isEditableVOLUME = false;
  private isEditableCOLLI = false;
  private isEditableUM = false;

  constructor(
    COD: string = null,
    DES: string = null,
    DIML: number = null,
    DIMA: number = null,
    DIMP: number = null,
    PESOL: number = null,
    PESON: number = null,
    VOLUME: number = null,
    COLLI: string = null,
    UM: string = null,
  ){
    this.COD = COD;
    this.DES = DES;
    this.DIML = DIML;
    this.DIMA = DIMA;
    this.DIMP = DIMP;
    this.PESOL = PESOL;
    this.PESON = PESON;
    this.VOLUME = VOLUME;
    this.COLLI = COLLI;
    this.UM = UM;
  }

  setValue(type: string, value: any) {
    switch (type) {
      case 'COD':
        this.COD = value;
        break;
      case 'DES':
        this.DES = value;
        break;
      case 'DIML':
        this.DIML = value;
        break;
      case 'DIMA':
        this.DIMA = value;
        break;
      case 'DIMP':
        this.DIMP = value;
        break;
      case 'PESOL':
        this.PESOL = value;
        break;
      case 'PESON':
        this.PESON = value;
        break;
      case 'VOLUME':
        this.VOLUME = value;
        break;
      case 'COLLI':
        this.COLLI = value;
        break;
      case 'UM':
        this.UM = value;
        break;
    }
  }

  setIsValueEditable(type: string, value) {
    switch (type) {
      case 'COD':
        this.isEditableCOD = value;
        break;
      case 'DES':
        this.isEditableDES = value;
        break;
      case 'DIML':
        this.isEditableDIML = value;
        break;
      case 'DIMA':
        this.isEditableDIMA = value;
        break;
      case 'DIMP':
        this.isEditableDIMP = value;
        break;
      case 'PESOL':
        this.isEditablePESOL = value;
        break;
      case 'PESON':
        this.isEditablePESON = value;
        break;
      case 'VOLUME':
        this.isEditableVOLUME = value;
        break;
      case 'COLLI':
        this.isEditableCOLLI = value;
        break;
      case 'COLLI':
        this.isEditableUM = value;
        break;
    }
  }

  isValueEditable(type: string) {
    switch (type) {
      case 'COD':
        return this.isEditableCOD;
      case 'DES':
        return this.isEditableDES;
      case 'DIML':
        return this.isEditableDIML;
      case 'DIMA':
        return this.isEditableDIMA;
      case 'DIMP':
        return this.isEditableDIMP;
      case 'PESOL':
        return this.isEditablePESOL;
      case 'PESON':
        return this.isEditablePESON;
      case 'VOLUME':
        return this.isEditableVOLUME;
      case 'COLLI':
        return this.isEditableCOLLI;
      case 'UM':
        return this.isEditableUM;
    }
  }
}
