/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from './servizi/layout.service';
import { GlobalVariableService } from './servizi/global-variable.service';
import { combineLatest, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StringheService } from './servizi/stringhe.service';
import {
  PreferenzaCatalogo,
  PreferenzeService,
} from './servizi/preferenze.service';
import { utenteErrataConComponenti } from './strutture/utenteErrataConComponenti.strutture';
import { ChangelogService } from './servizi/changelog.service';
import { changeLogStruct } from './strutture/changeLog.strutture';
import { DataManagerService } from './servizi/data-manager.service';
import * as defaults from './default-values';
import { CommonFunctionService } from './servizi/common-function.service';
import { UtentiService } from './servizi/utenti.service';
import { PopoverController } from '@ionic/angular';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  //* firebase notifications
  // title = 'af-notification';
  // message:any = null;




  isMobile$ = this.layoutService.isMobile$;
  showSideMenu: boolean = false;
  showTabsMenu: boolean = false;

  n_versione = defaults.NUMERO_VERSIONE;

  navigateNoMulticat = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      iconOutline: 'home-outline',
      iconFilled: 'home',
    },
    {
      title: 'Files',
      url: '/downloads',
      iconOutline: 'folder-outline',
      iconFilled: 'folder',
    },
    {
      title: 'Notifiche',
      url: '/notifications',
      iconOutline: 'notifications-outline',
      iconFilled: 'notifications',
    },
    {
      title: 'Team',
      url: '/team',
      iconOutline: 'people-outline',
      iconFilled: 'people',
    },
    {
      title: 'Impostazioni',
      url: '/settings',
      iconOutline: 'settings-outline',
      iconFilled: 'settings',
    },
  ];

  navigateMulticat = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      iconOutline: 'home-outline',
      iconFilled: 'home',
    },
    {
      title: 'Files',
      url: '/downloads',
      iconOutline: 'folder-outline',
      iconFilled: 'folder',
    },
    {
      title: 'Notifiche',
      url: '/notifications',
      iconOutline: 'notifications-outline',
      iconFilled: 'notifications',
    },
    {
      title: 'Cambia catalogo',
      url: '/scelta-catalogo',
      iconOutline: 'grid-outline',
      iconFilled: 'grid',
    },
    {
      title: 'Team',
      url: '/team',
      iconOutline: 'people-outline',
      iconFilled: 'people',
    },
    {
      title: 'Impostazioni',
      url: '/settings',
      iconOutline: 'settings-outline',
      iconFilled: 'settings',
    },
  ];

  catalogoArrivato: utenteErrataConComponenti;

  catalogo;
  catalogoAttivo: boolean = false;
  catalogoImg: string;
  catalogoName: string;
  activeCatalogoName: boolean = false;

  preferenzeCatalogo: PreferenzaCatalogo[];
  menuPers: boolean;

  utente;
  utenteArrivato: boolean = false;

  changelog: changeLogStruct[];

  private destroy$ = new Subject();

  status = new EventEmitter<boolean>();
  version = new EventEmitter<boolean>();
  stringhe = new EventEmitter<boolean>();

  stato: boolean;
  versione: boolean;
  stringheArr: boolean;

  parametroMailProvvisorio: boolean = false;
  cookieCheck: boolean = false;
  loginCheck: boolean = false;

  urlDaLink = window.location.href;
  urlTest = this.urlDaLink.search('test.pricelistserver.com');
  urlLocalhost = this.urlDaLink.search('localhost');

  activeBuild: boolean = false;
  activeTest: boolean = false;

  arrayParametri = [];

  constructor(public translate: TranslateService,
    public layoutService: LayoutService,
    public globarVars: GlobalVariableService,
    private router: Router,
    private cookieService: CookieService,
    private stringheService: StringheService,
    private preferenzeService: PreferenzeService,
    private changelogService: ChangelogService,
    private dataManager: DataManagerService,
    private str: StringheService,
    public commons: CommonFunctionService,
    private utentiService: UtentiService,
    private route: ActivatedRoute,
    private popoverCtrl: PopoverController,
  ) {}

  async ngOnInit() {
    //* firebase notifications
    // this.requestPermission();
    // this.listen();

    if (this.urlLocalhost > -1 || this.urlTest > -1) {
      console.log('active test');
      this.activeTest = true;
    } else {
      console.log('active build');
      this.activeBuild = true;
    }

    console.log('inPrint starting up...');
    await this.checkStatus();
    await this.checkVersione();
    await this.getStringhe();

    this.status.subscribe(async (checkStato) => {
      if (checkStato === true) {
        console.log('check stato', checkStato);
        await this.checkVersione();

        this.version.subscribe(async (checkVersione) => {
          this.versione = checkVersione;
          if (checkVersione === true) {
            await this.getStringhe();
            console.log('check versione', checkVersione);
            //Controllo se l'app sta girando in mobile o desktop
            let comandoPassato = this.urlDaLink.substring(
              this.urlDaLink.indexOf('#/') + 2,
              this.urlDaLink.indexOf('?')
            );
            if (
              comandoPassato === 'traduzione-articoli' ||
              comandoPassato === 'traduzione-intestazioni'
            ) {
              this.parametroMailProvvisorio = true;
            }
            if (this.parametroMailProvvisorio === true) {
              let urlDaNavigare = this.urlDaLink.split('/#/')[1];
              //Leggo il cookie di autenticazione
              if (this.cookieCheck == true) {
                //Vado alla traduzione caricando tutta l'interfaccia e spostandomi sull'articolo relativo
                console.log(
                  'mi è arrivata la traduzione on-line e ho il cookie'
                );

                const cookieContent = this.cookieService.get('userData');

                let cookieDiviso = this.readCookie(cookieContent);

                this.loginCheck = await this.utentiService.doLogin(
                  cookieDiviso[0],
                  cookieDiviso[1]
                );

                if (this.loginCheck) {
                  console.log(
                    'il login è ok e ho la traduzione on-demand da fare, proseguo in app'
                  );
                  var urlDaModificare = this.urlDaLink.search('/#/');
                  let comandoPassato = this.urlDaLink.substring(
                    this.urlDaLink.indexOf('#/') + 2,
                    this.urlDaLink.indexOf('?')
                  );
                  console.log('comando passato', comandoPassato);

                  switch (comandoPassato) {
                    case 'traduzione-articoli':
                      //vado al componente corretto
                      this.router.navigateByUrl(urlDaNavigare);

                      //passa alla parte dell'app corretta se l'utente ha fatto il login, altrimenti tieni quello di adesso
                      break;
                    case 'traduzione-intestazioni':
                      //vado al componente corretto
                      this.router.navigateByUrl(urlDaNavigare);
                      break;

                    default:
                      break;
                  }
                } else {
                  // navigo senza il login
                  this.router.navigateByUrl(urlDaNavigare);
                }
              } else {
                //Vado alla traduzione caricando solo l'interfaccia dell'articolo per fare SOLO la traduzione.
                console.log(
                  'mi è arrivata la traduzione on-line e NON ho il cookie'
                );
                this.router.navigateByUrl(urlDaNavigare);
              }
            } else {
              //Controllo se il cookie c'è
              this.cookieCheck = this.cookieService.check('userData');

              if (this.cookieCheck == true) {
                //Faccio comunque il login per verificare che il cookie sia valido, per esempio se l'utente ha cambiato password
                console.log(
                  'sono in login standard e ho trovato il cookie lo leggo e proseguo'
                );

                const cookieContent = this.cookieService.get('userData');

                let cookieDiviso = this.readCookie(cookieContent);

                console.log(
                  'autentico con API utente e password letti dal cookie utente:' +
                    cookieDiviso[0] +
                    'password:' +
                    cookieDiviso[1]
                );

                this.loginCheck = await this.utentiService.doLogin(
                  cookieDiviso[0],
                  cookieDiviso[1]
                );

                //Sparo all'API di login

                if (this.loginCheck == true) {
                  //Il cookie è valido e convalidato dall'API, proseguo all'ingresso in app
                  console.log(
                    'utente e pass da cookie sono corretti proseguo verso app'
                  );
                  this.utente = this.globarVars.getUTENTE_LOGIN()
                  if (this.utente !== undefined) {
                    this.utenteArrivato = true;
                  }
                  //Carico il menu laterale
                  this.menuLaterale();
                  //Avvio l'app
                  this.utentiService.userMoveIntoApp();
                } else {
                  //La verifica del cookie è fallita vado al login e CANCELLO il cookie
                  console.log(
                    'utente e pass non valide dal cookie (utente ha cambiato la password), cancello il cookie e vado al login'
                  );
                  this.globarVars.setUTENTE_LOGIN1(null)
                  this.cookieService.deleteAll();
                  this.utentiService.presentAlert();
                }
              } else {
                //Il cookie non c'è quindi devo andare al login
                console.log("Il cookie non c'è quindi devo andare al login");
                this.router.navigate(['login']);
              }
            }
          }
        });
      }
    });
  }

  menuLaterale() {
    combineLatest([this.isMobile$, this.globarVars.CATALOGO_ATTIVO$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([isMobile, catalogoAttivo]) => {
        if (!catalogoAttivo) {
          this.showSideMenu = false;
          //this.showTabsMenu = false;
          this.catalogoArrivato = catalogoAttivo;
          console.log('both menu false')
        } else if (isMobile) {
          this.showSideMenu = false;
          //this.showTabsMenu = true;
          console.log('side menu false')
        } else {
          this.showSideMenu = true;
          //this.showTabsMenu = false;
          console.log('side menu true con attivo catalogo', catalogoAttivo)
        }
      });
  }

  readCookie(cookieContent: string): string[] {
    const cookieDiviso = cookieContent.split('|');
    console.log('contenuto del cookie', cookieDiviso[0] + ' pw: ' + cookieDiviso[1]);

    return cookieDiviso;
  }

  async checkStatus() {
    const url = defaults.INPRINT_API + 'status.php';
    const parametri = new Map<string, any>();
    this.dataManager
      .dataRequestRitornoCodice(url, parametri, 'startup')

      .subscribe((codiceRitorno) => {
        if (codiceRitorno['RESULT'] === '200') {
          this.status.emit(true);
          console.log('inPrint è up and running');
        } else {
          this.status.emit(false);
          console.log('inPrint non è up, errore');
        }
      });
  }

  async checkVersione() {
    const url = defaults.INPRINT_API + 'checkVersione.php';
    const parametri = new Map<string, any>([
      ['APPID', defaults.APPID],
      ['TIPO', 2],
    ]);
    this.dataManager
      .dataRequestRitornoCodice(url, parametri, 'versione')
      .subscribe((codiceRitorno) => {
        console.log(codiceRitorno['RESULT']);

        let urlTest = this.urlDaLink.search('test.pricelistserver.com');
        let urlLocalhost = this.urlDaLink.search('localhost');
        let urlIp = this.urlDaLink.search('192.168.1');
        console.log('url di connessione ' + urlLocalhost);
        if (urlLocalhost > -1 || urlTest > -1 || urlIp > -1) {
          console.log('url privata modalità test, proseguo');
          this.version.emit(true);
        }
        if (codiceRitorno['RESULT'] === defaults.NUMERO_VERSIONE) {
          console.log(
            "le versioni corrispondono, posso continuare ad utilizzare l'app"
          );
          this.version.emit(true);
        } else {
          console.log(
            "le versioni NON corrispondono, avviso di aggiornamento dell'app"
          );
          this.version.emit(false);
        }
      });
  }

  async getStringhe() {
    console.log('stringe da inserire');
    const stringheDaInserire = await this.str.getStringhe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //* firebase notifications
  // requestPermission() {
  //   const messaging = getMessaging();
  //   getToken(messaging,
  //    { vapidKey: environment.firebase.vapidKey}).then(
  //      (currentToken) => {
  //        if (currentToken) {
  //          console.log("Hurraaa!!! we got the token.....");
  //          console.log(currentToken);
  //        } else {
  //          console.log('No registration token available. Request permission to generate one.');
  //        }
  //    }).catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //   });
  // }
  // listen() {
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     this.message=payload;
  //   });
  // }
}
function Output() {
  throw new Error('Function not implemented.');
}
