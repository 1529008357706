<ion-header class="ion-text-center">
  <ion-toolbar>
    <ion-title>
      {{this.translate.instant('183')}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="none">
    <ion-label>{{this.translate.instant('113')}}</ion-label>
    <ion-select mode="ios" (ionChange)="getListinoSelezionato($event)">
      <ion-select-option *ngFor="let listino of listini">{{listino.COD_LISTINO}}</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-list>
    <ion-list-header>Seleziona una o più lingue</ion-list-header>
    <ion-item *ngFor="let lingua of lingue; let i = index">
      <ion-checkbox id="{{i}}" (ionChange)="addLingua($event, lingua)" mode="ios" slot="start">{{lingua}}</ion-checkbox>
    </ion-item>
  </ion-list>

</ion-content>

<ion-footer class="ion-text-center">
  <ion-toolbar>
    <ion-button mode="ios" *ngIf="this.showBtn === true" (click)="inviaDati()">Prosegui</ion-button>
  </ion-toolbar>
</ion-footer>
