<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Nuova variante</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="fg">
    <ion-item>
      <ion-input label="Codice" labelPlacement="stacked" formControlName="CODVAR"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Descrizione" labelPlacement="stacked" formControlName="DESVAR"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Alias" labelPlacement="stacked" formControlName="ALIAS"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Traduzione gestionale" labelPlacement="stacked" formControlName="TRADGEST"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Tipo variante" labelPlacement="stacked" formControlName="TIPO_VARIANTE"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Nota" labelPlacement="stacked" formControlName="NOTA"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Ordinamento" labelPlacement="stacked" formControlName="ORDINAMENTO"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Opzioni fissate" labelPlacement="stacked" formControlName="OPZ_FIXED"></ion-input>
    </ion-item>
  </form>
</ion-content>

<ion-footer class="ion-no-border" mode='ios'>
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' (click)="doNuovaVariante()" color="primary" [disabled]="!fg.valid">Prosegui</ion-button>
  </ion-toolbar>
</ion-footer>
