import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AlertController,
  ModalController,
  ViewWillEnter,
} from '@ionic/angular';
import { Subject } from 'rxjs';
import { RicercaService } from 'src/app/servizi/ricerca.service';
import { righeMacroStruct } from 'src/app/strutture/righeMacro.strutture';
import { RicercaArticoliComponent } from '../../ricerca-articoli/ricerca-articoli.component';

import { TranslateService } from '@ngx-translate/core';
import {
  PreferenzaCatalogo,
  PreferenzaInPrint,
  PreferenzeService,
} from 'src/app/servizi/preferenze.service';
@Component({
  selector: 'app-nuova-riga',
  templateUrl: './nuova-riga.component.html',
  styleUrls: ['./nuova-riga.component.css'],
})
export class NuovaRigaComponent implements OnInit, ViewWillEnter {
  fg: FormGroup;
  modalDataResponse: any;
  codSelezionato = false;

  @Input() codice_testata: string = '';

  resetSearch = new Subject<void>();
  result;
  searchQuery = '';

  codaltTrovato: string = '';
  colonne: any;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private ricercaService: RicercaService,
    private preferenzeService: PreferenzeService,
    private alertCtrl: AlertController
  ) {}

  ionViewWillEnter(): void {
    this.getColonneAttive();
  }

  ngOnInit() {
    this.fg = this.fb.group({
      codice: ['', Validators.required],
      qta: ['', Validators.required],
      colonna: ['', Validators.required],
      operatore: ['', Validators.required],
    });
  }

  async doNuovaRiga() {
    const riga = new righeMacroStruct(
      this.codice_testata,
      0,
      this.fg.get('codice').value || this.modalDataResponse,
      this.fg.get('qta')?.value,
      this.fg.get('colonna')?.value,
      this.fg.get('operatore')?.value,
      this.codaltTrovato
    );
    console.log('riga da passare', riga);
    this.modalCtrl.dismiss(riga);
  }

  async ricercaArticoli() {
    const modal = await this.modalCtrl.create({
      component: RicercaArticoliComponent,
    });
    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
        this.codSelezionato = true;
        console.log('Modal Sent Data : ' + modalDataResponse.data);
      }
    });
    return await modal.present();
  }

  test() {
    if (this.fg.get('codice').value !== '') {
      this.ricercaService
        .ricerca(this.fg.get('codice').value)
        .then(async (response) => {
          console.log('response ricerca articoli', response.CODART[0]);
          if (this.fg.get('codice').value === response.CODART[0].cod) {
            console.log('ho trovato il codice');
            console.log(response);
            this.codaltTrovato = response.CODART[0]['codalt'];
          } else {
            console.log('non ho trovato codici corrispondenti');

            const alert = await this.alertCtrl.create({
              mode: 'ios',
              header: this.translate.instant('A004'),
              message: this.translate.instant('A009'),
              buttons: [
                {
                  text: this.translate.instant('001'),
                },
              ],
            });

            (await alert).present();
            await (await alert).onDidDismiss().then(() => {
              this.fg.reset();
            });
          }
        })
        .catch((error) => {
          // printLogerror);
        });
    }
  }

  async getColonneAttive() {
    let response = await this.preferenzeService.getPreferenzaCatalogo(
      'COLONNE_PRICELIST'
    );
    this.colonne = response.VALORE_PREFERENZA.split(',');
  }
}
