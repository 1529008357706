<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="1">
        <ion-icon style="float: left;" name="close-outline" (click)="modalCtrl.dismiss()"></ion-icon>
      </ion-col>
      <ion-col>
        <ion-text style="font-size: 1.3rem; font-weight: 600;">Variante derivata di {{opzione.CODOPZ}}</ion-text>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center">
        <ion-segment mode="md" [value]="selectedSegment" (ionChange)="changeVariantiToView($event)">
          <ion-segment-button value="assoc">
            <ion-label>Associate</ion-label>
          </ion-segment-button>
          <ion-segment-button value="nonassoc">
            <ion-label>Non associate</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>

    <ion-row class="header-row">
      <ion-col size="2">
        <ion-text class="header-text">Codice</ion-text>
      </ion-col>
      <ion-col size="9">
        <ion-text class="header-text">Descrizione</ion-text>
      </ion-col>
      <ion-col size="1">
        <ion-text class="header-text" style="float: right;">Opzioni</ion-text>
      </ion-col>
    </ion-row>

    <ion-row class="colored" *ngFor="let v of varianti" (click)="openOpzioni(v.CODVAR)">
      <ion-col size="2">
        <span><b>{{v.CODVAR}}</b></span>
      </ion-col>
      <ion-col size="9">
        <span>{{v.DESVAR}}</span>
      </ion-col>
      <ion-col size="1">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>


  </ion-grid>

</ion-content>
