import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';

@Injectable({
  providedIn: 'root',
})
export class AssociaColVarOpzService {
  constructor(private common: CommonFunctionService) {}

  async doAssociaVarColOpz(
    codopz: string,
    codvar: string,
    colonna_plist: string,
    opzscope: string,
    opzcontext: string
  ) {
    const doA = await this.common.doAssociaVarColOpz(
      codopz,
      codvar,
      colonna_plist,
      opzscope,
      opzcontext
    );
    console.log('response associazione colonna variante', doA);
  }

  async delAssociaVarColOpz(
    codopz: string,
    codvar: string,
    colonna_plist: string
  ) {
    const del = await this.common.delAssociaVarColOpz(
      codopz,
      codvar,
      colonna_plist
    );
    console.log('response deassociazione colonna variante', del);
  }

  async getAssociaCol(colonna_plist: string) {
    const get = await this.common.getAssociaCol(colonna_plist);
    //console.log('get associazione colonna', get)
    return get;
  }

  async getAssociaColVar(codvar: string, colonna_plist: string) {
    const get = await this.common.getAssociaColVar(codvar, colonna_plist);
    console.log('get associazione colonna variante', get);
    return get;
  }
}
