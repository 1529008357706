<ion-content *ngIf="this.viewRighe === false">
  <ion-grid>
    <ion-row class="header-row">
      <ion-col size="4">
        <ion-text class="header-text">Codice</ion-text>
      </ion-col>
      <ion-col size="4">
        <ion-text class="header-text">Descrizione</ion-text>
      </ion-col>
      <ion-col size="2">
        <ion-text class="header-text" style="float: right;">Visualizza</ion-text>
      </ion-col>
      <ion-col size="2">
        <ion-text class="header-text" style="float: right;">Seleziona</ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="colored" *ngFor="let d of distinte">
      <ion-col size="4">
        <ion-text class="header-text">{{d.COD_DISTINTA_CALCOLO}}</ion-text>
      </ion-col>
      <ion-col size="4">
        <ion-text class="header-text">{{d.DES_DISTINTA_CALCOLO}}</ion-text>
      </ion-col>
      <ion-col size="2">
        <ion-icon name="eye-outline" (click)="visualizzaRighe(d.COD_DISTINTA_CALCOLO)"></ion-icon>
      </ion-col>
      <ion-col size="2">
        <ion-icon name="checkbox-outline" color="success"
          (click)="selezionaDistinta(d.COD_DISTINTA_CALCOLO)"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>


<ion-content *ngIf="this.viewRighe === true">
  <ion-content>
    <ion-row>
      <ion-col>
        <ion-icon name="chevron-back-outline" style="float: left;" (click)="this.viewRighe = false"></ion-icon>
      </ion-col>
    </ion-row>

    <ion-grid>
      <ion-row class="header-row ion-text-center">
        <ion-col size="3">
          <ion-text class="header-text">Destinazione</ion-text>
        </ion-col>
        <ion-col size="3">
          <ion-text class="header-text">Partenza</ion-text>
        </ion-col>
        <ion-col size="2">
          <ion-text class="header-text">Percentuale</ion-text>
        </ion-col>
        <ion-col size="2">
          <ion-text class="header-text">Segno</ion-text>
        </ion-col>
        <ion-col size="2">
          <ion-text class="header-text">Arrotondamento</ion-text>
        </ion-col>
      </ion-row>

      <ion-row class="colored ion-text-center" *ngFor="let r of righe">
        <ion-col size="3">
          <ion-text>{{r.COL_DEST}}</ion-text>
        </ion-col>
        <ion-col size="3">
          <ion-text>{{r.COL_START}}</ion-text>
        </ion-col>
        <ion-col size="2">
          <ion-text>{{r.PERC}}</ion-text>
        </ion-col>
        <ion-col size="2">
          <ion-text>{{r.SEGNO}}</ion-text>
        </ion-col>
        <ion-col size="2">
          <ion-text>{{r.ARROT}}</ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>



  </ion-content>

</ion-content>
