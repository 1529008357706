/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { OpzioniService } from 'src/app/servizi/opzioni.service';
import { opzioniStruct } from 'src/app/strutture/opzioni.strutture';

import { TranslateService } from '@ngx-translate/core';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { VaultService } from 'src/app/servizi/vault.service';
@Component({
  selector: 'app-do-nuova-opzione',
  templateUrl: './do-nuova-opzione.component.html',
  styleUrls: ['./do-nuova-opzione.component.scss'],
})
export class DoNuovaOpzioneComponent implements OnInit {
  fg: FormGroup;
  codvar: string;
  codopz: string;

  textureName: string = '';
  fileToUpload: any;

  srcImage: string = '';

  hasTextureSelected: boolean = false;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private fb: FormBuilder,
    private os: OpzioniService,
    private alertCtrl: AlertController,
    private vaultS: VaultService,
    private globalvar: GlobalVariableService
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      CODOPZ: ['', Validators.required],
      DESOPZ: ['', Validators.required],
      TRADGEST: ['', Validators.required],
    });
  }

  async doNuovaOpzione() {
    this.codopz = this.fg.get('CODOPZ').value;
    const desopz = this.fg.get('DESOPZ').value;
    //? manca data_material quando avrò il picker e la gallery

    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: 'Attenzione',
      message:
        "Sei sicuro di voler creare l'opzione " +
        this.codopz +
        ' con descrizione ' +
        desopz +
        'per la variante ' +
        this.codvar +
        '?',
      buttons: [
        { text: 'Annulla', role: 'cancel' },
        { text: 'Crea', role: 'crea' },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'crea') {
      this.os.nuovaOpzione(this.codopz, this.codvar, desopz, '', '');
      this.modalCtrl.dismiss();
    }
  }

  //* upload texture
  async presentAlert(message: string) {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: 'Attenzione',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  onTextureSelected(event: any): void {
    const element = event.target as HTMLInputElement;
    const file = element?.files?.[0];

    this.textureName = file.name;
    this.fileToUpload = file;

    if (file) {
      const reader = new FileReader();
      console.log(file);

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const imageUrl = e.target?.result as string;
        const img = new Image();
        img.onload = () => {
          if (img.width === 200 && img.height === 200) {
            // L'immagine è della dimensione corretta
            console.log('Immagine valida');
            this.checkDSDirExists();
          } else {
            this.presentAlert(
              "La dimensione dell'immagine deve essere 200px per 200px"
            );
            console.log(
              'Immagine non valida',
              'width:' + img.width,
              'height:' + img.height
            );
          }
        };
        img.src = imageUrl;
      };

      reader.readAsDataURL(file);
    }
  }

  async checkDSDirExists() {
    //. Controllo se ho la cartella "deepspace"
    let hasDeepspaceDir = await this.vaultS.getContentVault('deepspace');
    if (hasDeepspaceDir.length > 0) {
      this.checkOpzDirExists();
    } else {
      //. Creo la cartella
      await this.vaultS.createFolderVault('deepspace/').then(() => {
        this.checkOpzDirExists();
      });
    }
  }

  async checkOpzDirExists() {
    //. Controllo se ho la cartella "opzioni" nella cartella "deepspace"
    let hasOptionDir = await this.vaultS.getContentVault('deepspace/opzioni');
    console.log('hasOptionDir', hasOptionDir);
    if (hasOptionDir.length > 0) {
      this.doUpload();
    } else {
      //. Creo la cartella
      await this.vaultS.createFolderVault('deepspace/opzioni/').then(() => {
        this.checkOpzDirExists();
      });
    }
  }

  async doUpload() {
    console.log("sono pronto per l'upload");

    console.log('carico il file', this.fileToUpload);
    let estensione = this.fileToUpload.name.split('.').pop();
    let nome = this.codvar + '_' + this.codopz;

    const formData = new FormData();
    formData.append('file', this.fileToUpload, nome + '.' + estensione);

    let filedapassare = formData.get('file') as File;
    console.log('file finale da passare', filedapassare);

    await this.vaultS.uploadVault(filedapassare, 'deepspace/opzioni/');
  }

  //* show texture
  async getTextureFromVault() {
    let nomeFile = this.codvar + '_' + this.codopz;
    let file = await this.vaultS.getContentVault(
      'deepspace/opzioni/' + nomeFile
    );

    let image =
      'https://api.price-list.it/inprintapi/api1/vault/viewerVault.php?APPID=2389045723&INI=' +
      this.globalvar.getDBNomeCatalogo() +
      '&PATH=' +
      file[0][0];

    console.log('immagine arrivata', image);
    this.srcImage = image;

    if (this.srcImage != '') {
      this.textureName = file[0][0];
    }
  }

  async openImage() {
    window.open(this.srcImage);
  }
}
