import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import * as defaults from '../../app/default-values';
// import { printLog } from '../lib';
import { stringheStruct } from '../strutture/stringhe.strutture';
import { DataManagerService } from './data-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class StringheService {
  constructor(
    private dataManager: DataManagerService,
    private translate: TranslateService
  ) {}

  async getStringhe(lingua = defaults.LINGUA) {
    this.translate.use(lingua);

    const url = defaults.INPRINT_API + 'getStringhe.php';
    const parametri = new Map<string, any>([
      ['APPID', defaults.APPID],
      ['LINGUA', lingua],
    ]);
    return this.dataManager
      .dataRequestRitornoCodice<stringheStruct[]>(url, parametri, 'getStringhe')
      .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
      .subscribe((value) => this.putStringa(value));
  }

  async putStringa(stringheArrivate: stringheStruct[]) {
    stringheArrivate.forEach((value) => {
      if (value.ID_STRINGA != 'IV') {
        this.translate.set(value.ID_STRINGA, value.STRINGA, value.LINGUA);
      }
    });
  }
}
