<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root" [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>{{this.translate.instant('260')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label>{{this.translate.instant('259')}} AR</ion-label>
          <ion-input labelPlacement="stacked" [(ngModel)]="CAT_AR"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label>{{this.translate.instant('099')}} PL</ion-label>
          <ion-input labelPlacement="stacked" [(ngModel)]="COL_PL"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button mode='ios'  (click)="doAssociazione()">{{this.translate.instant('031')}}</ion-button>
  </ion-toolbar>
</ion-footer>
