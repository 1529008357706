import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { testateListiniStruct } from 'src/app/strutture/listini calcolati distinta/testateListini.strutture';

@Component({
  selector: 'app-edit-testata-distinta',
  templateUrl: './edit-testata-distinta.component.html',
  styleUrls: ['./edit-testata-distinta.component.scss'],
})
export class EditTestataDistintaComponent implements OnInit {
  fg: FormGroup;
  @Input() testata: testateListiniStruct;

  constructor(
    private fb: FormBuilder,
    public modalCtrl: ModalController,
    private ls: ListiniService,
    private router: Router
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      DES_DISTINTA: [this.testata.DES_DISTINTA_CALCOLO, Validators.required],
    });
  }

  async editT() {
    const modifiedFields = { field: '', value: '' };
    Object.keys(this.fg.controls).forEach((controlName) => {
      const control = this.fg.get(controlName);
      if (control.dirty && control.value !== this.testata[controlName]) {
        modifiedFields.field = controlName;
        modifiedFields.value = control.value;
      }
    });
    console.log('Campi modificati:', modifiedFields);

    await this.ls
      .editTListinoDistinta(
        this.testata.COD_DISTINTA_CALCOLO,
        modifiedFields.field,
        modifiedFields.value
      )
      .then(() => {
        this.modalCtrl.dismiss();
      });
  }
}
