/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertButton, AlertController, ModalController } from '@ionic/angular';
import { VariantiService } from 'src/app/servizi/varianti.service';
import { variantiStruct } from 'src/app/strutture/varianti.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-do-nuova-variante',
  templateUrl: './do-nuova-variante.component.html',
  styleUrls: ['./do-nuova-variante.component.scss'],
})
export class DoNuovaVarianteComponent implements OnInit {

  fg: FormGroup;

  constructor(public translate: TranslateService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private vs: VariantiService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    console.log('nuova variante');
    this.fg = this.fb.group({
      CODVAR: ['', Validators.required],
      DESVAR: ['', Validators.required],
      ALIAS: [''],
      TRADGEST: [''],
      TIPO_VARIANTE: [''],
      NOTA: [''],
      ORDINAMENTO: [''],
      OPZ_FIXED: ['']
  });
  }

  async close(message = 'Modal Closed') {
    await this.modalCtrl.dismiss(message);
  }

  async doNuovaVariante(){
    const v = new variantiStruct(
      this.fg.get('CODVAR').value,
      this.fg.get('DESVAR').value,
      this.fg.get('ALIAS').value,
      this.fg.get('TRADGEST').value,
      this.fg.get('TIPO_VARIANTE').value,
      1,
      this.fg.get('NOTA').value,
      this.fg.get('ORDINAMENTO').value,
      this.fg.get('OPZ_FIXED').value,
      '0',
      '',
    );
    console.log('variante', JSON.stringify(v));

    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: 'Attenzione',
      message: 'Sei sicuro di voler creare la variante ' + this.fg.get('CODVAR').value + ' con descrizione ' + this.fg.get('DESVAR').value + '?',
      buttons: [
        {text: 'Annulla', role: 'cancel'},
        {text: 'Crea', role: 'crea'}
      ]
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if(role === 'crea'){
      this.vs.nuovaVariante(v);
      this.modalCtrl.dismiss();
    }

  }



}
